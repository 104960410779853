.cookie-text {
    color: white;
}

.revision-caller {
    position: fixed;
    bottom: 0;
    left: 10px;
    z-index: 3000;
    cursor: pointer
}

.revision-caller-butt {
    background-color: black;
    border-radius: 6px;
    border: 0;
}